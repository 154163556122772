import React from 'react';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';

export default class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.menu = null;
    this.menuIcon = null;

    this.hasNavigationShown = false;
  }

  componentDidMount() {
    this.timelineLoading = new TimelineMax()
      .to({}, 1.22, {})
      .add(() => TweenMax.to(this.menu, 0.8, { ease: 'Mo', opacity: 1 }));
  }

  updateMenuOpacity = (value, time) => {
    TweenMax.to(this.menu, time, { ease: 'Mo', opacity: value });
  };

  onMenuMouseEnter = () => {
    TweenMax.to(this.menuIcon, 0.2, { ease: 'Mo', scaleX: 1.1 });
  };

  onMenuMouseLeave = () => {
    if (this.hasNavigationShown) {
      setTimeout(() => {
        this.hasNavigationShown = false;
        TweenMax.to(this.menuIcon, 0.2, { ease: 'Mo', scaleX: 1 });
      }, 0.8);
    } else {
      TweenMax.to(this.menuIcon, 0.2, { ease: 'Mo', scaleX: 1 });
    }
  };

  showNavigation = () => {
    this.hasNavigationShown = true;
    this.updateMenuOpacity(0, 0.8);
    this.props.updateRenderStates('navigation', true);
  };

  render() {
    return (
      <section className="base">
        <div
          className="menu"
          ref={div => (this.menu = div)}
          onMouseEnter={this.onMenuMouseEnter}
          onMouseLeave={this.onMenuMouseLeave}
          onClick={this.showNavigation}
        >
          <div className="menu__icon" ref={div => (this.menuIcon = div)}>
            <span className="menu__icon__line" />
            <span className="menu__icon__line" />
            <span className="menu__icon__line" />
          </div>
          {/* <img className="menu__icon" src="../assets/menu.svg" /> */}
        </div>
        <div className="grid--vertical">
          <div className="grid__container">
            <div className="grid__line--vertical" />
            <div className="grid__line--vertical" />
            <div className="grid__line--vertical" />
            <div className="grid__line--vertical" />
            <div className="grid__line--vertical" />
            <div className="grid__line--vertical" />
          </div>
        </div>
      </section>
    );
  }
}
